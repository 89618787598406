import { Col, Form, InputNumber, Row } from 'antd';
import useGetCurrentFeeConfig from './useGetCurrentFeeConfig';
import { feeTips } from 'server/type-file';

export function OtherItem() {
	const form = Form.useFormInstance();

	const { valuation, isLoading } = useGetCurrentFeeConfig({
		roomNumber: form.getFieldValue('roomNumber'),
	});

	return (
		<Row>
			<Col span={8}>
				<Form.Item
					label="卫生费"
					name="cleanFee"
					hidden={valuation.cleanFee.disabled}
					tooltip={feeTips(valuation.cleanFee)}
				>
					<InputNumber placeholder="卫生费" />
				</Form.Item>
			</Col>
			<Col span={8}>
				<Form.Item
					label="电视费"
					name="tvFee"
					hidden={valuation.tvFee.disabled}
					tooltip={feeTips(valuation.tvFee)}
				>
					<InputNumber placeholder="电视费" />
				</Form.Item>
			</Col>
			<Col span={8}>
				<Form.Item
					label="网络费"
					name="networkFee"
					hidden={valuation.networkFee.disabled}
					tooltip={feeTips(valuation.networkFee)}
				>
					<InputNumber placeholder="网络费" />
				</Form.Item>
			</Col>
		</Row>
	);
}
