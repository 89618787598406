import { TrpcBase } from '../../../utils/trpc';
import { parseValuation, defaultConfig } from 'server/type-file';
import { isNil } from 'lodash-es';

export default function useGetCurrentFeeConfig({
	roomNumber,
}: { roomNumber: string }) {
	const query = TrpcBase.apartment.getCurrentFeeConfig.useQuery(
		{
			roomNumber,
		},
		{
			enabled: !isNil(roomNumber),
		},
	);

	const valuation = query.data ? parseValuation(query.data) : defaultConfig;

	return { valuation, ...query };
}
