import { Button, Col, Form, InputNumber, Row } from 'antd';
import { OtherItem } from './OtherItem';
import { getColdWaterItemLabelByConfig } from './getColdWaterItemLabelByConfig';
import useGetCurrentFeeConfig from './useGetCurrentFeeConfig';
import { feeTips } from 'server/type-file';

// import request from './request';
export function CheckInItem() {
	const form = Form.useFormInstance();
	const { valuation } = useGetCurrentFeeConfig({
		roomNumber: form.getFieldValue('roomNumber'),
	});

	return (
		<>
			<Form.Item
				label="功能-统一水电表当日读数"
				name="electricMeterReadingLastMonth"
			>
				<Button
					onClick={() => {
						form.setFieldsValue({
							electricMeterReadingThisMonth: form.getFieldValue(
								'electricMeterReadingLastMonth',
							),
							hotWaterMeterReadingThisMonth: form.getFieldValue(
								'hotWaterMeterReadingLastMonth',
							),
							coldWaterMeterReadingThisMonth: form.getFieldValue(
								'coldWaterMeterReadingLastMonth',
							),
						});
					}}
				>
					统一
				</Button>
			</Form.Item>

			<Row>
				<Col span={12}>
					<Form.Item
						label="电表当日读数"
						name="electricMeterReadingLastMonth"
						rules={[
							{
								required: !valuation.electricityFee.disabled,
								message: '请输入电表当日读数',
							},
						]}
						hidden={valuation.electricityFee.disabled}
						tooltip={feeTips(valuation.electricityFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="电表当日读数"
						name="electricMeterReadingThisMonth"
						hidden={valuation.electricityFee.disabled}
						tooltip={feeTips(valuation.electricityFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
			</Row>

			<Row>
				<Col span={12}>
					<Form.Item
						label="热水水表当日读数"
						name="hotWaterMeterReadingLastMonth"
						rules={[
							{
								required: !valuation.hotWaterFee.disabled,
								message: '请输入热水水表当日读数',
							},
						]}
						hidden={valuation.hotWaterFee.disabled}
						tooltip={feeTips(valuation.hotWaterFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="热水水表当日读数"
						name="hotWaterMeterReadingThisMonth"
						hidden={valuation.hotWaterFee.disabled}
						tooltip={feeTips(valuation.hotWaterFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
			</Row>

			<Row>
				<Col span={12}>
					<Form.Item
						label={`${getColdWaterItemLabelByConfig(valuation)}水表当日读数`}
						name="coldWaterMeterReadingLastMonth"
						rules={[
							{
								required: !valuation.coldWaterFee.disabled,
								message: `请输入${getColdWaterItemLabelByConfig(
									valuation,
								)}水表当日读数`,
							},
						]}
						hidden={valuation.coldWaterFee.disabled}
						tooltip={feeTips(valuation.coldWaterFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label={`${getColdWaterItemLabelByConfig(valuation)}水表当日读数`}
						name="coldWaterMeterReadingThisMonth"
						hidden={valuation.coldWaterFee.disabled}
						tooltip={feeTips(valuation.coldWaterFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
			</Row>

			<Form.Item
				label="押金"
				name="deposit"
				rules={[
					{ required: true, message: '请输入押金' },
					{
						// https://ant.design/components/form-cn#rule
						pattern: /\d[05]{2,3}/,
						message: '押金一般是整数或X50结尾',
						warningOnly: true,
					},
				]}
			>
				<InputNumber />
			</Form.Item>

			<OtherItem />
		</>
	);
}
